var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dynamic-wrap"},[_vm._l((_vm.keysList),function(item){return _c('div',{key:item},[_c('a-form-item',{staticStyle:{"width":"28%","margin-left":"4%"},attrs:{"label":"设备名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                (_vm.title + "Name[" + item + "]"),
                {
                  initialValue: _vm.arr[item] ? _vm.arr[item].name : undefined,
                }
              ]),expression:"[\n                `${title}Name[${item}]`,\n                {\n                  initialValue: arr[item] ? arr[item].name : undefined,\n                }\n              ]"}],attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1),_c('a-form-item',{staticStyle:{"width":"28%","margin-left":"-4%"},attrs:{"label":"设备编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                (_vm.title + "Num[" + item + "]"),
                {
                  initialValue: _vm.arr[item] ? _vm.arr[item].num : undefined,
                }
              ]),expression:"[\n                `${title}Num[${item}]`,\n                {\n                  initialValue: arr[item] ? arr[item].num : undefined,\n                }\n              ]"}],attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1),_c('a-form-item',{staticStyle:{"width":"28%","margin-left":"-4%"},attrs:{"label":"安装位置"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                (_vm.title + "Address[" + item + "]"),
                {
                  initialValue: _vm.arr[item] ? _vm.arr[item].address : undefined,
                }
              ]),expression:"[\n                `${title}Address[${item}]`,\n                {\n                  initialValue: arr[item] ? arr[item].address : undefined,\n                }\n              ]"}],attrs:{"disabled":_vm.showType==='detail',"placeholder":""}})],1),_c('a-form-item',{staticStyle:{"width":"10%","margin-left":"-4%"}},[(_vm.showType!=='detail'&&_vm.keysList.length > 1)?_c('a-button',{staticClass:"deleteRowBtn",attrs:{"type":"danger","ghost":"","icon":"delete"},on:{"click":function () { return _vm.removeRow(item); }}},[_vm._v(" 删除 ")]):_vm._e()],1)],1)}),_c('a-row',[_c('a-col',[_c('a-form-item',{staticStyle:{"margin-left":"10%"}},[(_vm.showType!=='detail')?_c('a-button',{staticClass:"addRowBtn",staticStyle:{"width":"300px"},attrs:{"type":"dashed","icon":"plus"},on:{"click":_vm.addRow}},[_vm._v("新增")]):_vm._e()],1)],1)],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }