<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1000" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:16}">
        <a-tabs type="card" v-model="activeKey">
          <a-tab-pane key="1" tab="设备机房信息"></a-tab-pane>
          <a-tab-pane key="2" tab="归属机房设备" v-if="showType!=='add'"></a-tab-pane>
          <a-tab-pane key="3" tab="巡查记录" v-if="showType==='detail'"></a-tab-pane>
        </a-tabs>
        <div v-show="activeKey == '1'">
          <!-- =======================设备机房信息======================= -->
          <h3 style="font-size: large;font-weight: bold; color: #0388fd;">设备机房信息<a-button v-if="btnList.includes('导出')" type="primary" @click="exportMachineRoomIDCard(formData)" style="float:right;margin-right: 20px">导出</a-button></h3>
          <a-form-model-item label="项目名称" prop="monitorpointnum">
            <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择">
              <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="机房分类" prop="machine_room_type">
            <a-select :disabled="showType=='detail'" v-model="formData.machine_room_type">
              <a-select-option v-for="(item, index) in machineRoomTypeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="设备类型" prop="equipment_type">
            <a-select :disabled="showType=='detail'" v-model="formData.equipment_type">
              <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="机房名称" prop="machine_room_name">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.machine_room_name" />
          </a-form-model-item>
          <a-form-model-item label="机房编号" prop="machine_room_num">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.machine_room_num" />
          </a-form-model-item>
          <a-form-model-item label="楼层" prop="floor">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.floor" />
          </a-form-model-item>
          <a-form-model-item label="轴位" prop="axial_position">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.axial_position" />
          </a-form-model-item>
          <a-form-model-item label="面积" prop="measure">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.measure" />
          </a-form-model-item>
          <a-form-model-item label="关联摄像机名称/编号" prop="monitor_num">
            <a-input :disabled="true" v-model.trim="formData.monitor_num" />
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remarks">
            <textarea :disabled="showType=='detail'" v-model.trim="formData.remarks" style="width: 305px"/>
          </a-form-model-item>
          <a-form-model-item label="机房平面图" prop="machine_room_pic">
            <a-upload
                :disabled="showType=='detail'"
                name="fileList"
                :headers="uploadHeaders"
                :data="uploadFacilityEquipment"
                list-type="picture-card"
                action="/upload"
                :file-list="machineRoomPicList"
                @preview="handlePreview"
                @change="machineRoomPicUpload">
              <a-button v-if="showType!='detail'"><a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item  label="机房二维码" prop="qrcode">
            <div id="qrCode" ref="qrCodeDiv"></div>
          </a-form-model-item>
        </div>
        <div v-show="activeKey == '2'">
          <!-- =======================绑定机房设备======================= -->
          <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主设备</h3>
          <div style="text-align: right;">
            <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="selectEquip(0)">选择</a-button>
          </div>
          <a-table :columns="tableColumns" :data-source="primaryEquipmentList" row-key="directories_id" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 10px;" :pagination="false">
            <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
            <span slot="equipment_status" slot-scope="value,record">{{equipmentStatusList[value]?equipmentStatusList[value].dicvalue:''}}</span>
          </a-table>
          <h3 style="font-size: large;font-weight: bold; color: #0388fd;">辅助设备</h3>
          <div style="text-align: right;margin-top: 10px">
            <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="selectEquip(1)">选择</a-button>
          </div>
          <a-table :columns="tableColumns" :data-source="spareEquipmentList" row-key="directories_id" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 10px;" :pagination="false">
            <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
            <span slot="equipment_status" slot-scope="value,record">{{equipmentStatusList[value]?equipmentStatusList[value].dicvalue:''}}</span>
          </a-table>
        </div>
        <div v-show="activeKey == '3'">
          <h3 style="font-size: large;font-weight: bold; color: #0388fd;">巡查记录</h3>
          <div class="box-container" style="margin-top: 10px;">
            <div class="box-container-inner">
              <div class="simple-query">
                <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
                  <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
                    <a-range-picker v-model="queryInstallDate"></a-range-picker>
                  </a-form-model-item>
                  <a-form-model-item label="巡查人员" prop="username" style="width: 25%">
                    <a-select v-model="queryParams.username">
                      <a-select-option v-for="(item, index) in usernameList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="巡查结论" prop="result_code" style="width: 25%">
                    <a-select v-model="queryParams.result_code">
                      <a-select-option v-for="(item, index) in resultCodesList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <div class="query-btns" style="float: right;width: 24%">
                    <a-button @click="resetQueryParams">重置</a-button>
                    <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
                    <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
                  </div>
                  <div v-show="showAdvanced" class="advanced-query">
                    <a-form-model-item label="巡查时长" prop="inspection_interval" style="width: 25%">
                      <a-select v-model="queryParams.inspection_interval">
                        <a-select-option value="0~30分钟">0~30分钟</a-select-option>
                        <a-select-option value="30分钟~1小时">30分钟~1小时</a-select-option>
                        <a-select-option value="1小时~3小时">1小时~3小时</a-select-option>
                        <a-select-option value="3小时~1天">3小时~1天</a-select-option>
                        <a-select-option value="大于1天">大于1天</a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </div>
                </a-form-model>
              </div>
              <div class="box-container-inner">
                <div class="table-header">
                  <h3 class="table-title">巡查记录</h3>
                  <div class="table-btns">
                    <!--            <a-button type="primary" @click="showModal()"><a-icon type="plus"></a-icon>新建</a-button>-->
                    <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
                  </div>
                </div>
                <a-table :columns="inspectionTableColumns" :customRow="customRow" :data-source="tableData" row-key="inspection_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="inspection_time" slot-scope="value, record">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
                  <span slot="result_code" slot-scope="value, record">
            <span>{{value?resultCodes[value]:""}}</span>
          </span>
                  <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.inspection_id">详情</a-menu-item>
                <!--                <a-menu-item :key="'edit-'+record.inspection_id">修改</a-menu-item>-->
                <!--                <a-menu-item :key="'delete-'+record.inspection_id">修改</a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
                </a-table>
                <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
                  <template slot="buildOptionText" slot-scope="props">
                    <span>{{ props.value }}条/页</span>
                  </template>
                </a-pagination>
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
    </a-modal>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" width="1080px">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <select-primary-equipment :visible.sync="selectPrimaryEquipmentVisible" :default-selected="primaryEquipmentList" :formData="formData" @get-primary-selected-rows="getPrimaryEquipmentList"></select-primary-equipment>
    <select-spare-equipment :visible.sync="selectSpareEquipmentVisible" :default-selected="spareEquipmentList" :formData="formData" @get-spare-selected-rows="getSpareEquipmentList"></select-spare-equipment>
    <detail-modal :visible.sync="InspectionModalVisible" :detail-data="InspectionModalDetailData" @get-operation-result="getTableData"></detail-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {
  addMachineRoom, exportMachineRoomIDCardWord, getMachineRoomInfoById,
  modifyMachineRoom
} from "A/facilityandequipment";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import SelectPrimaryEquipment from "C/modals/SelectPrimaryEquipment";
import SelectSpareEquipment from "C/modals/SelectSpareEquipment";
import QRCode from "qrcodejs2";
import {getEngineerInspectionListByCondition, getEngineerInspectionUsernameList} from "A/patrol";
import {resultCodes, resultCodesList} from "@/json/patrol";
import pagination from "@/mixins/pagination";
import DetailModal from "V/workCheck/engineer/patrolCheck/DetailModal";
export default {
  mixins: [areaselect, deptselect,pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    ActiveKey: {
      default: '1'
    },
  },
  components: {
    SelectPrimaryEquipment,
    SelectSpareEquipment,
    DetailModal,
  },
  data() {
    return {
      moment,
      size: 'default',
      modalBodyStyle: {
        height: '675px',
        overflowY: 'auto',
      },
      modalVisible: false,
      activeKey: '1',
      formData: {
        //机房信息
        machine_room_id: '',
        monitorpointnum: '',
        monitorpointname: '',
        machine_room_type: '',
        equipment_type: '',
        machine_room_num: '',
        machine_room_name: '',
        floor: '',
        axial_position: '',
        measure: '',
        machine_room_pic: '',
        monitor_num: '',
        remarks: '',
        creat_time: '',

        primaryEquipIds:'',
        primaryEquipmentList:[],
        spareEquipIds:'',
        spareEquipmentList:[],
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目名称'}],
        machine_room_type: [{required: true, message: '请选择机房分类'}],
        machine_room_num: [{required: true, message: '请选择机房编号'}],
        equipment_type: [{required: true, message: '请选择设备类型'}],
        machine_room_name: [{required: true, message: '请输入机房名称'}],
      },
      tableColumns: [
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
        },
        {
          title: '图位号',
          dataIndex: 'draw_num',
          key: 'draw_num',
          ellipsis: true,
        },
        {
          title: '备注',
          dataIndex: 'remarks',
          key: 'remarks',
          ellipsis: true,
        },
      ],
      primaryEquipIds:'',
      primaryEquipmentList:null,
      spareEquipIds:'',
      spareEquipmentList:null,
      selectPrimaryEquipmentVisible:false,
      selectSpareEquipmentVisible:false,
      userdepidCascaderSelected: [],
      machineRoomPicList: [],
      baseurl:'https://fda.u-lake.com/',
      previewImage: '',
      previewVisible:false,
      monitorpointList:[],
      deviceCodeList:[],
      machineRoomTypeList:[],
      showAdvanced: false,
      resultCodes,
      resultCodesList,
      usernameList:[],
      queryInstallDate: null,
      queryParams: {
        monitorpointname:'',
        machine_room_name:'',
        username:'',
        result_code:'',
        inspection_interval:'',
        starttime:'',
        endtime:'',
      },
      inspectionTableColumns: [
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '机房名称',
          dataIndex: 'machine_room_name',
          key: 'machine_room_name',
          ellipsis: true,
        },
        {
          title: '巡查人员',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '巡查时间',
          dataIndex: 'inspection_time',
          key: 'inspection_time',
          ellipsis: true,
          scopedSlots: { customRender: 'inspection_time' }
        },
        {
          title: '巡查时长',
          dataIndex: 'inspection_interval',
          key: 'inspection_interval',
          ellipsis: true,
        },
        {
          title: '巡查结论',
          dataIndex: 'result_code',
          key: 'result_code',
          ellipsis: true,
          scopedSlots: { customRender: 'result_code' }
        },
        {
          title: '签到地址',
          dataIndex: 'inspection_address',
          key: 'inspection_address',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      InspectionModalVisible: false,
      InspectionModalShowType: '',
      InspectionModalDetailData: null,

      //操作按钮权限
      btnList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadFacilityEquipment']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.activeKey=this.ActiveKey
        if(this.detailData){
          this.btnList=this.detailData.btnList
        }
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    primaryEquipmentList(val) {
      if(val && val.length) {
        this.formData.primaryEquipIds = val.map(item => item.directories_id).join('|');
      }else {
        this.formData.primaryEquipIds = '';
      }
    },
    spareEquipmentList(val) {
      if(val && val.length) {
        this.formData.spareEquipIds = val.map(item => item.directories_id).join('|');
      }else {
        this.formData.spareEquipIds = '';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.machineRoomTypeList = dictionary.machineRoomTypeList;
    }
    this.getUsernameList();
    this.initDeptOptionsAll();
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
      this.machineRoomPicList=[]
      if(this.$refs.qrCodeDiv){
        this.$refs.qrCodeDiv.innerHTML = "" //清除上一个二维码
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.machine_room_id && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          let params = {
            machine_room_id: this.detailData.machine_room_id
          }
          getMachineRoomInfoById(params).then(res => {
            if (res && res.returncode == '0') {
              this.formData = res.item;
              this.primaryEquipmentList=res.item.primaryEquipmentList
              this.spareEquipmentList=res.item.spareEquipmentList
              // 特别数据回显
              if (this.formData.machine_room_pic) {
                let nameIndex = this.formData.machine_room_pic.lastIndexOf('/');
                if (nameIndex < 0) {
                  nameIndex = this.formData.machine_room_pic.lastIndexOf('\\');
                }
                let fileName = this.formData.machine_room_pic.substr(nameIndex + 1);
                this.machineRoomPicList = [{
                  uid: 'machine_room_picid',
                  name: fileName,
                  status: 'done',
                  url: this.formData.machine_room_pic,
                }];
              }
              this.$nextTick(function () {
                this.bindQRCode();
              })
              this.getTableData(true);
            }
          })
        })
      }else{
        this.primaryEquipmentList=[]
        this.spareEquipmentList=[]
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add') {
              this.showLoading();
              addMachineRoom(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'){
              this.showLoading();
              modifyMachineRoom(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    exportMachineRoomIDCard(record){
      let params={
        ...record
      }
      exportMachineRoomIDCardWord(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("操作成功")
        }
      })
    },
    machineRoomPicUpload(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.machine_room_pic = file.response.urlPath;
        }
        return file;
      });
      this.machineRoomPicList = fileList;
    },
    async handlePreview() {
      this.previewImage = this.baseurl+this.formData.machine_room_pic;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    selectEquip(type) {
      this.formData.equipment_apart = type
      if(type==0){
        this.selectPrimaryEquipmentVisible = true
      }else if(type==1){
        this.selectSpareEquipmentVisible = true
      }
    },
    getPrimaryEquipmentList(rows) {
      this.primaryEquipmentList = rows;
      this.formData.primaryEquipmentList=[]
      for(let i=0;i<rows.length;i++){
        this.formData.primaryEquipmentList.push({
          directories_id:rows[i].directories_id,
          equipment_num:rows[i].equipment_num,
          equipment_name:rows[i].equipment_name,
          draw_num:rows[i].draw_num,
          remarks:rows[i].remarks,
        })
      }
    },
    getSpareEquipmentList(rows) {
      this.spareEquipmentList = rows;
      this.formData.spareEquipmentList=[]
      for(let i=0;i<rows.length;i++){
        this.formData.spareEquipmentList.push({
          directories_id:rows[i].directories_id,
          equipment_num:rows[i].equipment_num,
          equipment_name:rows[i].equipment_name,
          draw_num:rows[i].draw_num,
          remarks:rows[i].remarks,
        })
      }
    },
    bindQRCode: function () {
      if(this.$refs.qrCodeDiv){
        this.$refs.qrCodeDiv.innerHTML = "" //清除上一个二维码
      }
      new QRCode(this.$refs.qrCodeDiv, {
        text: this.formData.monitorpointnum+'-'+this.formData.machine_room_num,
        width: 150,
        height: 150,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
    },
    getUsernameList(){
      getEngineerInspectionUsernameList({}).then(res=>{
        if(res&&res.returncode==='0'){
          this.usernameList=res.item
        }
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[];
      this.queryInstallDate=[];
      this.$refs.queryForm.resetFields();
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.InspectionModalDetailData=record
            this.InspectionModalShowType='detail'
            this.InspectionModalVisible=true
          },
        },
      }
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        monitorpointnum:this.formData.monitorpointnum,
        machine_room_name:this.formData.machine_room_name,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEngineerInspectionListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let inspection_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'inspection_id', inspection_id);
      if(type == 'detail') {
        this.InspectionModalDetailData=record
        this.InspectionModalShowType='detail'
        this.InspectionModalVisible=true
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>