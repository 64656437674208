// 设施设备模块
import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

//添加设施设备信息
export function addEquipDirectories(params) {
  return http({
    url: `${preApi}/equipment/addEquipDirectories`,
    method: "post",
    data: params
  })
}
//删除设施设备信息
export function deleteEquipDirectories(params) {
  return http({
    url: `${preApi}/equipment/deleteEquipDirectories`,
    method: "post",
    data: params
  })
}
//修改设施设备信息
export function modifyEquipDirectories(params) {
  return http({
    url: `${preApi}/equipment/modifyEquipDirectories`,
    method: "post",
    data: params,
  })
}
//根据条件获取设施设备信息列表
export function getEquipDirectoriesListByCondition(params) {
  return http({
    url: `${preApi}/equipment/getEquipDirectoriesListByCondition`,
    method: "post",
    data: params,
  })
}
//根据条件查询设施设备巡查条目信息列表
export function getInspectionEquipDirectoriesList(params) {
  return http({
    url: `${preApi}/equipment/getInspectionEquipDirectoriesList`,
    method: "post",
    data: params,
  })
}
//选择设施设备信息列表
export function selectEquipDirectoriesList(params) {
  return http({
    url: `${preApi}/equipment/selectEquipDirectoriesList`,
    method: "post",
    data: params,
  })
}
//根据条件获取设施设备列表
export function getEquipDirectoriesList(params) {
  return http({
    url: `${preApi}/equipment/getEquipDirectoriesList`,
    method: "post",
    data: params,
  })
}
//导出设施设备excel
export function exportEquipDirectoriesExcel(params) {
  return http({
    url: `${preApi}/equipment/exportEquipDirectoriesExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
//导出总线设备excel
export function exportBusDeviceExcel(params) {
  return http({
    url: `${preApi}/equipment/exportBusDeviceExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
//导出设备标识卡word
export function exportEquipmentIDCardWord(params) {
  params.filename = '设备标识卡.docx';
  return http({
    url: `${preApi}/equipment/exportEquipmentIDCardWord`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//添加总线设备
export function addBusDevice(params) {
  return http({
    url: `${preApi}/equipment/addBusDevice`,
    method: "post",
    data: params
  })
}
//删除总线设备
export function deleteBusDevice(params) {
  return http({
    url: `${preApi}/equipment/deleteBusDevice`,
    method: "post",
    data: params
  })
}
//修改总线设备信息
export function modifyBusDevice(params) {
  return http({
    url: `${preApi}/equipment/modifyBusDevice`,
    method: "post",
    data: params,
  })
}
//根据条件获取总线设备信息列表
export function getBusDeviceListByCondition(params) {
  return http({
    url: `${preApi}/equipment/getBusDeviceListByCondition`,
    method: "post",
    data: params,
  })
}

//添加机房信息
export function addMachineRoom(params) {
  return http({
    url: `${preApi}/equipment/addMachineRoom`,
    method: "post",
    data: params
  })
}
//删除机房信息
export function deleteMachineRoom(params) {
  return http({
    url: `${preApi}/equipment/deleteMachineRoom`,
    method: "post",
    data: params
  })
}
//修改机房信息
export function modifyMachineRoom(params) {
  return http({
    url: `${preApi}/equipment/modifyMachineRoom`,
    method: "post",
    data: params,
  })
}
//根据条件获取机房信息列表
export function getMachineRoomListByCondition(params) {
  return http({
    url: `${preApi}/equipment/getMachineRoomListByCondition`,
    method: "post",
    data: params,
  })
}
//根据ID获取机房信息
export function getMachineRoomInfoById(params) {
  return http({
    url: `${preApi}/equipment/getMachineRoomInfoById`,
    method: "post",
    data: params,
  })
}
//导出机房信息excel
export function exportMachineRoomExcel(params) {
  return http({
    url: `${preApi}/equipment/exportMachineRoomExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
//导出设备标识卡word
export function exportMachineRoomIDCardWord(params) {
  params.filename = '机房标识卡.docx';
  return http({
    url: `${preApi}/equipment/exportMachineRoomIDCardWord`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//添加运行计划信息
export function addOperationPlan(params) {
  return http({
    url: `${preApi}/equipment/addOperationPlan`,
    method: "post",
    data: params
  })
}

//删除运行计划信息
export function deleteOperationPlan(params) {
  return http({
    url: `${preApi}/equipment/deleteOperationPlan`,
    method: "post",
    data: params
  })
}

//修改运行计划信息
export function modifyOperationPlan(params) {
  return http({
    url: `${preApi}/equipment/modifyOperationPlan`,
    method: "post",
    data: params,
  })
}
//根据条件获取运行计划信息列表
export function getOperationPlanListByCondition(params) {
  return http({
    url: `${preApi}/equipment/getOperationPlanListByCondition`,
    method: "post",
    data: params,
  })
}
//导出运行计划excel
export function exportOperationPlanExcel(params) {
  return http({
    url: `${preApi}/equipment/exportOperationPlanExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}


//添加设施设备信息
export function addQrcodeContrast(params) {
  return http({
    url: `${preApi}/equipment/addQrcodeContrast`,
    method: "post",
    data: params
  })
}
//删除设施设备信息
export function deleteQrcodeContrast(params) {
  return http({
    url: `${preApi}/equipment/deleteQrcodeContrast`,
    method: "post",
    data: params
  })
}
//修改设施设备信息
export function modifyQrcodeContrast(params) {
  return http({
    url: `${preApi}/equipment/modifyQrcodeContrast`,
    method: "post",
    data: params,
  })
}
//根据条件获取设施设备信息列表
export function getQrcodeContrastListByCondition(params) {
  return http({
    url: `${preApi}/equipment/getQrcodeContrastListByCondition`,
    method: "post",
    data: params,
  })
}
//根据条件设备或者机房名称列表
export function getEquipAndMachineNameList(params) {
  return http({
    url: `${preApi}/equipment/getEquipAndMachineNameList`,
    method: "post",
    data: params,
  })
}
//根据条件设备或者机房信息列表
export function getEquipAndMachineInfoList(params) {
  return http({
    url: `${preApi}/equipment/getEquipAndMachineInfoList`,
    method: "post",
    data: params,
  })
}