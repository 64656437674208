<template>
  <div>
    <div class="dynamic-wrap">
      <div v-for="item in keysList" :key="item">
          <a-form-item label="设备名称" style="width: 28%;margin-left: 4%">
            <a-input
                :disabled="showType==='detail'"
                placeholder=""
                v-decorator="[
                  `${title}Name[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].name : undefined,
                  }
                ]"
            />
          </a-form-item>
          <a-form-item label="设备编号" style="width: 28%;margin-left: -4%">
            <a-input
                :disabled="showType==='detail'"
                placeholder=""
                v-decorator="[
                  `${title}Num[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].num : undefined,
                  }
                ]"
            />
          </a-form-item>
          <a-form-item label="安装位置" style="width: 28%;margin-left: -4%">
            <a-input
                :disabled="showType==='detail'"
                placeholder=""
                v-decorator="[
                  `${title}Address[${item}]`,
                  {
                    initialValue: arr[item] ? arr[item].address : undefined,
                  }
                ]"
            />
          </a-form-item>
          <a-form-item  style="width: 10%;margin-left: -4%">
            <a-button
                type="danger"
                ghost
                class="deleteRowBtn"
                v-if="showType!=='detail'&&keysList.length > 1"
                @click="() => removeRow(item)"
                icon="delete"
            >
              删除
            </a-button>
          </a-form-item>
      </div>
      <a-row>
        <a-col>
          <a-form-item style="margin-left: 10%">
            <a-button v-if="showType!=='detail'" type="dashed" class="addRowBtn" @click="addRow" icon="plus" style="width: 300px">新增</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DynamicForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    showType: {
      default: 'add'
    },
    arr: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      labelCol: {
        span: 10
      },
      wrapperCol: {
        span: 14
      },
      id: 0,
      keysList: [],
      moment
    }
  },
  watch:{
    arr:function (){
      const arr = [0]
      if (this.arr.length !== 0) {
        for (let i = 1; i < this.arr.length; i++) {
          arr.push(i)
          // this.id = this.id + 1
        }
      }
      this.keysList = arr
    }
  },
  created() {
    this.form = this.$form.createForm(this)
    this.init()
  },
  methods: {
    // 初始化
    init() {
      if(this.arr.length===1&&this.arr[0].name===''){
        const arr = [0]
        if (this.arr.length !== 0) {
          for (let i = 1; i < this.arr.length; i++) {
            arr.push(i)
            this.id = this.id + 1
          }
        }
        this.keysList = arr
      }
    },
    // 移除某行
    removeRow(k) {
      if (this.keysList.length === 1) {
        // 如果存在可以移除所有行的情况，把条件改为this.keysList.length === 0即可
        return
      }
      this.keysList = this.keysList.filter(item => item !== k)
      let params = {
        type:"移除一行",
        index:k
      }
      this.$emit('update-result', params);
      //console.log("移除第",k,"行")
    },
    // 新增一行
    addRow() {
      this.id = this.id + 1
      this.keysList = this.keysList.concat(this.keysList.length)
      let params = {
        type:"新增一行",
        index:this.keysList.length
      }
      this.$emit('update-result', params);
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  }
}
</script>
<style lang="scss" scoped>
.dynamic-wrap {
  padding-top: 10px;
}
.minusRowBtn {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
  padding-right: 7px;
  padding-left: 7px;
  height: 29px;
  margin-left: 10px;
}
.deleteRowBtn {
  width: 120%;
  margin-left: -6px;
}
.addRowBtn {
  width: 80%;
  color: #1890ff;
  border-color: #91d5ff;
  margin-left: -3px;
}
</style>
