<template>
  <a-modal v-model="modalVisible" title="选择辅助设备" :width="1200" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="select-modal-container">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline">
        <a-form-model-item label="设备名称" prop="equipment_name">
          <a-input v-model.trim="queryParams.equipment_name" placeholder="请输入" style="width: 140px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="设备编号" prop="equipment_num">
          <a-input v-model.trim="queryParams.equipment_num" placeholder="请输入" style="width: 140px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="设备类型" prop="equipment_type">
          <a-select v-model="queryParams.equipment_type" style="width: 140px">
            <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="图位号" prop="draw_num">
          <a-input v-model.trim="queryParams.draw_num" placeholder="请输入" style="width: 140px"></a-input>
        </a-form-model-item>
        <a-form-model-item style="float: right">
          <a-button @click="resetQueryForm">重置</a-button>
          <a-button type="primary" style="margin-left: 10px;" @click="getTableData(true)">查询</a-button>
        </a-form-model-item>
      </a-form-model>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="directories_id" :row-selection="{ type: selectType, selectedRowKeys: selectedRowKeys, onSelect: onSelectRow, onSelectAll: onSelectAllRows }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 16px;" :pagination="false">
        <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
        <span slot="equipment_apart" slot-scope="value,record">{{value==0?"主设备":value=="1"?"辅助设备":""}}</span>
        <span slot="equipment_status" slot-scope="value,record">{{equipmentStatusList[value]?equipmentStatusList[value].dicvalue:''}}</span>
        <span slot="is_bind" slot-scope="value"><a-tag :color="value=='已绑定'?'green':''">{{value}}</a-tag></span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </a-modal>
</template>
<script>
import pagination from '@/mixins/pagination'
import {selectEquipDirectoriesList} from "A/facilityandequipment";
import {getCache} from "U/index";
export default {
  mixins: [pagination],
  props: {
    visible: {
      default: false
    },
    selectType: {
      default: 'checkbox'
    },
    defaultSelected: {
      default() {
        return []
      }
    },
    params: {
      default: null
    },
    formData:{
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      modalVisible: false,
      queryParams: {
        equipment_name: '',
        equipment_num: '',
        equipment_type: '',
        equipment_status:'',
        draw_num:'',
      },
      tableLoading: false,
      tableColumns: [
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_type' }
        },
        {
          title: '设备状态',
          dataIndex: 'equipment_status',
          key: 'equipment_status',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_status' }
        },
        {
          title: '图位号',
          dataIndex: 'draw_num',
          key: 'draw_num',
          ellipsis: true,
        },
        {
          title: '是否绑定',
          dataIndex: 'is_bind',
          key: 'is_bind',
          ellipsis: true,
          scopedSlots: { customRender: 'is_bind' }
        },
        {
          title: '绑定机房名',
          dataIndex: 'machine_room_name',
          key: 'machine_room_name',
          ellipsis: true,
        },
        {
          title: '设备所属',
          dataIndex: 'equipment_apart',
          key: 'equipment_apart',
          ellipsis: true,
          scopedSlots: { customRender: 'equipment_apart' }
        },
      ],
      deviceCodeList:[],
      equipmentStatusList:[],
      tableData: [],
      selectedRows: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      }
    }
  },
  computed: {
    selectedRowKeys() {
      let currentSelectKeys = this.selectedRows.map(item => item.directories_id.toString());
      return currentSelectKeys;
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.selectedRows = this.defaultSelected || [];
        this.init();
      }else {
        this.selectedRows = []
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    params(val) {
      if(val) {
        this.queryParams = {...this.queryParams, ...val};
      }
    },
  },
  created() {
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.equipmentStatusList = dictionary.equipmentStatusList
    }
  },
  methods: {
    init() {
      if(this.params) {
        this.queryParams = {...this.queryParams, ...this.params};
      }
      this.modalVisible = this.visible;
      this.getTableData(true)
    },
    resetQueryForm() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        equipment_apart:1,
        machine_room_id:this.formData.machine_room_id,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      selectEquipDirectoriesList(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item.map(item => {
            item.directories_id = '' + item.directories_id;
            return item;
          });
          this.pagination.total = res.count;
        }
      })
    },
    onSelectRow(record, selected, selectedRows, nativeEvent) {
      if(selected) {
        if(this.selectType == 'radio') {
          this.selectedRows = [record]
        }else if(this.selectedRowKeys.indexOf(record.directories_id) < 0) {
          this.selectedRows.push(record);
        }
      }else {
        this.selectedRows = this.selectedRows.filter(item => item.directories_id!=record.directories_id)
      }
    },
    onSelectAllRows(selected, selectedRows, changeRows) {
      if(selected) {
        this.tableData.forEach(record => {
          if(this.selectedRowKeys.indexOf(record.directories_id) < 0) {
            this.selectedRows.push(record);
          }
        })
      }else {
        this.tableData.forEach(record => {
          this.selectedRows = this.selectedRows.filter(item => item.directories_id!=record.directories_id)
        })
      }
    },
    confirm() {
      this.$emit('get-spare-selected-rows', this.selectedRows);
      this.modalVisible = false;
    }
  }
}
</script>